:root {
    --gray: #666;
    --dark: #333;
    --black: #000;
    --white: #fff;
    --muted: #6c757d;
    --light: #f7f7f7;
    --accent: #047786;
    --primary: #df2a63;
    --accent-dark: #011114;
    --accent-light: #E4F1F1;
    --accent-secondary: #069eb2;
    --body: var(--dark)
}

.navbar-toggler {
    background-color: var(--light) !important;
    color: var(--black) !important;
}

.segment-button-checked {
    background-color: var(--white) !important;
    background: var(--white) !important;
    color: var(--primary) !important;
}

div, li, p, div, h1, h2, h3, h4, h5, h6, .btn {
    color: var(--body);
}

#field-wrapper {
    border: 1px solid rgba(248, 249, 250, 0.5);
}

.input-group  {
    border-radius: 25px !important;
}

.input-group-text {
    border-radius: 25px 0px 0px 25px;
}

.input-group, .input-group .input-group-text {
    background-color: var(--light) !important;
}

.form-control {
    color: var(--black);
    border-color: var(--accent);
    background-color: var(--light) !important;
}

.form-control:focus {
    color: var(--black);
    border-color: var(--accent);
    background-color: var(--white) !important;
}

.card {
    background-color: var(--white);
}

.modal-content {
    background-color: var(--white);
}

/** Buttons **/

.btn.border {
    border-color: var(--black) !important;
}

.btn-primary, .btn-outline-primary:hover {
    color: white !important; 
    border: 1px solid var(--primary) !important;
    background-color: var(--primary) !important;
}

.btn-outline-primary {
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
}

.btn-outline-accent {
    border: 1px solid var(--accent) !important;
    color: var(--accent) !important;
}

.btn.border {
    border: 1px solid #000 !important;
}

.btn-accent { 
    background-color: var(--accent) !important;
    border: 1px solid var(--accent) !important;
    color: white !important;
}

/** Border **/
.border-primary {
    border-color: var(--primary) !important;
}

.border-secondary {
    border-color: var(--accent) !important;
}

.border-dark {
    border-color: var(--dark) !important;
}

/** Background **/

.bg-dark {
    background-color: var(--accent-dark) !important;
}

.bg-success {
    background-color: var(--accent) !important;
}

.bg-white {
    background-color: var(--white) !important;
}

.bg-black {
    background-color: #000;
}

.bg-gray {
    background-color: var(--gray);
}

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-gradient {
    background-image: linear-gradient(90deg, #069eb2, #DED240);
}

.bg-accent {
    background-color: var(--accent);
}

.bg-accent-light {
    background-color: var(--accent-light);
}

.bg-light {
    background-color: var(--light) !important;
}

/** Text **/

.text-accent-secondary {
    color: var(--accent-secondary) !important
}

.text-gradient {
    background-image: linear-gradient(90deg, var(--primary), var(--accent-secondary));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.text-muted {
    color: var(--muted) !important;
}

.text-dark {
    color: var(--dark) !important;
}

.text-accent {
    color: var(--accent) !important;
}

.text-primary {
    color: var(--primary) !important;
}

/** General **/

a, .text-link {
    color: var(--accent) !important;
}

.menu-category:hover {
    background-color: var(--accent);
}

.menu-category:active {
    box-shadow: none;
    background-color: var(--accent);
}

.menu-category:focus {
    box-shadow: none;
    background-color: var(--accent);
}

.nav-item:hover {
    background-color: var(--accent);
    color: #000;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--accent);
    color: #fff;
}

.user {
    background-color: var(--accent);
}

.nav-item .mobile:focus {
    background-color: var(--accent);
}

.image-input-container {
    border: 2px solid var(--accent);
}

.user-menu {
    background-color: var(--gray);
}


.hover-light:hover {
    color: var(--dark) !important;
    background-color: var(--light);
}

.status-badge {
    background-color: var(--accent-light);
}

/* Ionic */

.ion-color-accent {
    background-color: var(--accent-light) !important;
    padding: 4px 6px;
}

/** Hover **/

.hover-success:hover {
    color: white !important;
    background-image: linear-gradient(90deg, var(--primary), var(--accent));
}
.hover-success:hover * {
    color: white !important;
}