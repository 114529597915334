#navbar {
    height: 80px;
    width: 100%;
}

#main {
    overflow: scroll !important;
}

#hero-img {
    position: absolute; 
    width: 1000px; 
    top: -300px;
}

#landing {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.hero-section {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.display-1 span {
    font-size: inherit;
}

@media (max-width: 1200px) {
    #hero-img {
        position: relative;
        max-width: 100%;
        width: 100%;
        top: 0px;
    }

     .hero-section {
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}