@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);
.avatars__container {
  overflow: auto;
  height: calc(100% - 135px);
}

@media(max-width: 768px) {
  .avatars__container {
    height: calc(100% - 90px);
  }
}
#main {
  overflow: hidden;
  max-height: 100vh;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#main::-webkit-scrollbar {
  display: none;
}

#navbar {
  max-width: 100vw;
  overflow: hidden;
}

.ct.ct--bottom {
  display: none;
}

.react-editor .ce-toolbar__settings-btn,
.react-editor .ce-toolbar__plus {
  position: relative;
}

.react-editor .ce-toolbar__settings-btn:hover::before,
.react-editor .ce-toolbar__plus:hover::before {
  font-size: 12px;
  background-color: #333;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 15px;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 999;
}

.react-editor .ce-toolbar__settings-btn:hover::before {
  content: "Actions";
}

/* Additional styles specific to .ce-toolbar__plus */
.react-editor .ce-toolbar__plus:hover::before {
  content: "Insert Element";
}

.offcanvas {
  max-width: 85%;
  padding-top: 5vh;
}

.progress-dot {
  top: 55%;
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50px;
  background-color: var(--primary);
}

.icon-wrapper {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper-lg {
  width: 125px;
  height: 125px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
}

.due-date-card {
  border-left: 8px solid var(--primary) !important;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
}

.step-card,
.activity-card,
.process-card {
  width: 70vw;
  border: 0px;
  height: 139px;
  position: relative;
  display: inline-block;
}

.step-card .progress-container,
.process-card .progress-container {
  position: absolute;
  bottom: 15px;
}

.step-card .metadata-container,
.process-card .metadata-container {
  position: absolute;
  bottom: 25px;
}

.step-card h3,
.activity-card h3,
.process-card h3 {
  height: 2.8rem;
}

.step-metadata,
.activity-metadata,
.process-metadata {
  font-size: 12px;
}

.step-container,
.activity-container,
.process-container {
  max-height: 170px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.step-wrapper,
.activity-wrapper {
  width: 180%;
}

.vimeo-player {
  height: 65vh;
}

.btn-view {
  border-radius: 0px 10px 10px 0px;
  border-width: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 35px;
}

.output-input {
  width: auto;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 2.5rem;
  min-width: 70vw;
}

.output-input:focus {
  box-shadow: none !important;
}

span.input {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: 1px solid var(--primary);
}

span.input:focus-visible {
  box-shadow: none;
  border-bottom: 1px solid var(--primary);
}

.prompt-generator * :not(.dropdown-item),
.prompt-generator span {
  font-size: 4.5rem;
}

.prompt-generator .dropdowm-item {
  font-size: 2rem;
}

.purchases-table td.datetime,
.purchases-table th.datetime {
  min-width: 150px;
}

.loading {
  font-size: 30px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@media (max-width: 1400px) {
  .prompt-generator * {
    font-size: 4rem;
  }
}

@media (max-width: 1200px) {
  .prompt-generator * {
    font-size: 3.5rem;
  }
}

@media (max-width: 1000px) {
  .prompt-generator * {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  #walkthrough-video iframe {
    width: 60vw;
    height: 200px;
    justify-content: end;
  }
  .prompt-generator * {
    font-size: 2rem;
  }
}

#navbar {
    height: 80px;
    width: 100%;
}

#main {
    overflow: scroll !important;
}

#hero-img {
    position: absolute; 
    width: 1000px; 
    top: -300px;
}

#landing {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.hero-section {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.display-1 span {
    font-size: inherit;
}

@media (max-width: 1200px) {
    #hero-img {
        position: relative;
        max-width: 100%;
        width: 100%;
        top: 0px;
    }

     .hero-section {
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}
:root {
    --gray: #666;
    --dark: #333;
    --black: #000;
    --white: #fff;
    --muted: #6c757d;
    --light: #f7f7f7;
    --accent: #047786;
    --primary: #df2a63;
    --accent-dark: #011114;
    --accent-light: #E4F1F1;
    --accent-secondary: #069eb2;
    --body: var(--dark)
}

.navbar-toggler {
    background-color: var(--light) !important;
    color: var(--black) !important;
}

.segment-button-checked {
    background-color: var(--white) !important;
    background: var(--white) !important;
    color: var(--primary) !important;
}

div, li, p, div, h1, h2, h3, h4, h5, h6, .btn {
    color: var(--body);
}

#field-wrapper {
    border: 1px solid rgba(248, 249, 250, 0.5);
}

.input-group  {
    border-radius: 25px !important;
}

.input-group-text {
    border-radius: 25px 0px 0px 25px;
}

.input-group, .input-group .input-group-text {
    background-color: var(--light) !important;
}

.form-control {
    color: var(--black);
    border-color: var(--accent);
    background-color: var(--light) !important;
}

.form-control:focus {
    color: var(--black);
    border-color: var(--accent);
    background-color: var(--white) !important;
}

.card {
    background-color: var(--white);
}

.modal-content {
    background-color: var(--white);
}

/** Buttons **/

.btn.border {
    border-color: var(--black) !important;
}

.btn-primary, .btn-outline-primary:hover {
    color: white !important; 
    border: 1px solid var(--primary) !important;
    background-color: var(--primary) !important;
}

.btn-outline-primary {
    border: 1px solid var(--primary) !important;
    color: var(--primary) !important;
}

.btn-outline-accent {
    border: 1px solid var(--accent) !important;
    color: var(--accent) !important;
}

.btn.border {
    border: 1px solid #000 !important;
}

.btn-accent { 
    background-color: var(--accent) !important;
    border: 1px solid var(--accent) !important;
    color: white !important;
}

/** Border **/
.border-primary {
    border-color: var(--primary) !important;
}

.border-secondary {
    border-color: var(--accent) !important;
}

.border-dark {
    border-color: var(--dark) !important;
}

/** Background **/

.bg-dark {
    background-color: var(--accent-dark) !important;
}

.bg-success {
    background-color: var(--accent) !important;
}

.bg-white {
    background-color: var(--white) !important;
}

.bg-black {
    background-color: #000;
}

.bg-gray {
    background-color: var(--gray);
}

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-gradient {
    background-image: linear-gradient(90deg, #069eb2, #DED240);
}

.bg-accent {
    background-color: var(--accent);
}

.bg-accent-light {
    background-color: var(--accent-light);
}

.bg-light {
    background-color: var(--light) !important;
}

/** Text **/

.text-accent-secondary {
    color: var(--accent-secondary) !important
}

.text-gradient {
    background-image: linear-gradient(90deg, var(--primary), var(--accent-secondary));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.text-muted {
    color: var(--muted) !important;
}

.text-dark {
    color: var(--dark) !important;
}

.text-accent {
    color: var(--accent) !important;
}

.text-primary {
    color: var(--primary) !important;
}

/** General **/

a, .text-link {
    color: var(--accent) !important;
}

.menu-category:hover {
    background-color: var(--accent);
}

.menu-category:active {
    box-shadow: none;
    background-color: var(--accent);
}

.menu-category:focus {
    box-shadow: none;
    background-color: var(--accent);
}

.nav-item:hover {
    background-color: var(--accent);
    color: #000;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--accent);
    color: #fff;
}

.user {
    background-color: var(--accent);
}

.nav-item .mobile:focus {
    background-color: var(--accent);
}

.image-input-container {
    border: 2px solid var(--accent);
}

.user-menu {
    background-color: var(--gray);
}


.hover-light:hover {
    color: var(--dark) !important;
    background-color: var(--light);
}

.status-badge {
    background-color: var(--accent-light);
}

/* Ionic */

.ion-color-accent {
    background-color: var(--accent-light) !important;
    padding: 4px 6px;
}

/** Hover **/

.hover-success:hover {
    color: white !important;
    background-image: linear-gradient(90deg, var(--primary), var(--accent));
}
.hover-success:hover * {
    color: white !important;
}
html {
  width: 100vw;
  max-width: 100vw;
}

body {
  background-color: var(--light);
}

#root {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}

.frame#webpack-dev-server-client-overlay {
  display: none;
}

iframe[style*="z-index: 2147483647;"] {
  display: none !important;
}

body > iframe:nth-child(13) {
  display: none;
}

input[type="color"] {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  height: 38px;
  border-radius: 15px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 15px;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 15px;
}

.row {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.container-overflow-y::-webkit-scrollbar {
  display: none;
}

.small {
  font-size: 12px !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.sidebar {
  width: 180px;
  z-index: 100;
  position: fixed;
  height: 100%;
  border-radius: 10px;
  max-height: 95vh;
  overflow-y: auto;
}

.hide-scrollbar {
  scrollbar-width: thin; /* para Firefox */
  scrollbar-color: transparent transparent; /* para Firefox */
  overflow: -moz-scrollbars-none; /* para Firefox */
}

/* Para navegadores basados en WebKit como Chrome y Safari */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
  display: none;
}

.hide-scrollbar:-webkit-scrollbar-track {
  display: none;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-menu {
  margin-top: 20%;
}

.active-scroll-y {
  overflow-y: auto;
}

.disable-scroll-y {
  overflow-y: hidden;
}

.avatar-input-context { 
  height: calc((100% - 162px) / 2);
  min-height: 215px;
}

.avatar-files-section {
  min-height: 215px;
  overflow-y: hidden;
  height: calc((100% - 225px) / 2);
}

.avatar-files-section.editable {
  height: calc((100% - 162px) / 2);
}

.scroll-bar-hidden::-webkit-scrollbar {
  display: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important;
}

nav > ol > li > a {
  text-decoration: none;
  color: #888888 !important;
}

.inset-shadow {
  box-shadow: inset 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.content {
  height: 100%;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  overflow-x: hidden;
  margin-left: 190px;
  border-radius: 10px;
  width: calc(100vw - 190px);
}

.navbar-logo {
  width: 55px;
}

.badge {
  font-size: 12px;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hidden {
  visibility: hidden;
}

.app-link {
  cursor: pointer;
  position: relative;
}

.user-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 150px;
  object-fit: cover;
}

.home-row {
  height: 50vh;
}

.home-img {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-icon {
  max-width: 70px;
}

.transfer-deposit {
  max-height: 400px;
  object-fit: contain;
  object-position: left;
}

.alert {
  z-index: 1000;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.panel-header-logo {
  max-width: 65px;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.capitalize {
  text-transform: capitalize;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  position: absolute;
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.text-large {
  font-size: 1em;
}

.carousel-inner {
  border-radius: 10px;
}

.carousel-img {
  width: 100%;
  border-radius: 10px;
  max-height: 50vh;
  object-fit: cover;
}

.class-container {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.schedule-class {
  cursor: pointer;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-col {
  width: calc(100% / 7);
}

.schedule-container {
  width: 170%;
}

.br-10 {
  border-radius: 10px;
}

.br-25 {
  border-radius: 25px;
}

.video-panel {
  position: relative;
}

.video-panel-title {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.video-panel-overlay {
  background-color: #000;
  position: absolute;
  height: 50vh;
  opacity: 0.5;
  width: 100%;
  left: 0px;
  top: 0px;
}

.video-panel-img {
  height: 50vh;
  object-fit: cover;
}

.swipe-container {
  max-width: 100%;
  display: flex;
  overflow-x: scroll;
  transition: all 0.5s;
  scroll-behavior: smooth;
  margin-bottom: 16px;
}

.swipe-container::-webkit-scrollbar {
  display: none;
}

.day-card .card {
  min-height: 100px;
  background-color: transparent;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(0%);
  }
}

.card {
  border: 0px;
  border-radius: 8px;
  transition: all 0.5s;
}

.card-footer {
  border-radius: 10px;
}

/* .output-card {
  animation: 1s ease-out 0s 1 slideInFromRight;
} */

.user-message {
  animation: 1s ease-out 0s 1 slideInFromRight;
}

.system-message {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.payment-method {
  max-width: 75px;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.border-2 {
  border-width: 2px;
  border-style: solid;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.head-coach-ribbon {
  position: absolute;
  font-weight: 800;
  color: #000;
  left: 0px;
  top: 20px;
  width: 30px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.slideshow-logo {
  max-width: 450px;
}

.slideshow-img {
  height: 100vh;
  object-fit: cover;
}

.slideshow-text {
  position: absolute;
  top: 25%;
  padding: 16px;
  z-index: 10;
  max-width: 600px;
}

.z-10 * {
  z-index: 10;
}

.hero-content {
  max-width: 600px;
}

.carousel-overlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 75%,
    rgba(255, 255, 255, 0) 150%
  );
  opacity: 0.5;
  position: absolute;
  display: block;
  height: 80vh;
  width: 100%;
  top: 0;
}

.menu-category {
  font-size: 1.2rem;
  width: 100%;
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
}

.navbar {
  margin-top: -2rem;
}

.nav-item {
  height: 60px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  font-weight: 800;
}

.navbar-cta {
  margin-top: 10px;
  transition: all 0.25s;
}

.navbar-cta:hover {
  transform: scale(1.05);
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  display: inline-block;
  color: inherit;
}

.nav-link:hover {
  color: inherit;
}

.logo-landing {
  max-width: 300px;
}

.text-funny {
  font-size: 4rem;
}

.text-left {
  text-align: left !important;
}

.side-menu {
  left: 0;
  width: 160px;
  color: #fff;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  padding-top: 32px;
  min-height: 100vh;
}

.dropdown-item {
  color: #fff;
}

.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.h-max-content {
  height: -webkit-max-content !important;
  height: max-content !important;
}

.w-max-content {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.settings-nav .nav-item:hover {
  background-color: unset ;
}

.input-group-text {
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.document-avatar-selector__control--is-focused {
  border-color: var(--accent) !important;
  box-shadow: 0 0 0 1px var(--accent) !important;
}

.document-avatar-selector__option--is-selected {
  background-color: unset !important;
  background-image: linear-gradient(90deg, var(--primary), var(--accent-secondary));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.disable-hover-bg:hover {
  background-color: unset !important;
}

.template-card__description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.template-apply-container {
  height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.offcanvas * {
  z-index: 1045;
}

.profile-image {
  max-width: 300px;
}

.video-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.thumbnail {
  max-width: 16px;
}

.profile-thumbnail {
  border-radius: 100px;
  max-width: 100%;
}

.video-thumbnail {
  height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
}

.card-video-title {
  font-weight: 800;
}

.card-video {
  border-radius: 10px;
  height: 350px;
  margin-bottom: 32px;
  padding-bottom: 16px;
  color: #fff;
}

.card-video:hover {
  transform: scale(1.1);
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.banner-bg {
  padding-top: 150px;
  padding-left: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
}

.filter-card {
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.user-name {
  font-size: 20px;
}

.user {
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}

.home-video {
  height: 90vh;
  object-fit: cover;
}

#video-row {
  margin-top: 60px !important;
}

.logo-navbar {
  max-width: 150px;
}

.trailer {
  max-width: 1100px;
  width: 100%;
}

.mvh-100 {
  min-height: 100vh;
}

.vh-100 {
  height: 100vh;
}

.text-banner {
  animation: animate 60s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0%);
  }
}

#main-landing {
  overflow-x: hidden;
  max-width: 100vw;
}

.login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.auth.container {
  background-color: transparent;
  max-width: 500px;
}

#particles-container {
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
}

#particles-js {
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.user-menu {
  margin-left: -22%;
  color: #fff;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
}

.br-0 {
  border-radius: 0px !important;
}

.fixed-bottom {
  z-index: 10;
}

.bottom-navbar {
  position: fixed;
  z-index: 100;
  bottom: 0px;
  width: 100%;
}

.bottom-navbar i {
  font-size: 24px;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.list-style-none {
  list-style-type: none;
  padding-left: 0px;
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  transform: scale(1.1);
}

.border-0 .item-inner {
  border-bottom-width: 0px !important;
}

.bold {
  font-weight: 800;
}

.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.mw-800 {
  max-width: 800px;
}

.h-auto {
  height: auto;
}

.background-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.strikethrough {
  text-decoration: line-through;
}

.coach-home-img {
  height: 400px;
  object-fit: cover;
}

.black-overlay {
  background-color: #000;
  display: block;
  opacity: 0.4;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
}

.image-gallery-image {
  height: 400px !important;
  object-fit: contain !important;
}

.image-gallery-thumbnail-image {
  height: 70px !important;
  object-fit: contain !important;
}

label,
span,
input {
  font-size: 16px;
}

.wmde-markdown.wmde-markdown-color {
  background-color: inherit !important;
}

.form-check-input:checked {
  background-color: var(--accent);
  border-color: var(--accent);
}

.form-control > .white-bg {
  background-color: white !important;
}

.btn:not(.btn-view) {
  border-radius: 25px;
}
.btn:not(.btn-round):not(.btn-view) {
  padding-left: 16px;
  padding-right: 16px;
}

.show-mobile {
  display: none;
}

.show-mobile.d-inline-block {
  display: none !important;
}

.bg-vertical {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

div.app-landing {
  width: 100%;
  position: absolute;
  padding-bottom: 4rem !important;
  bottom: 0px;
}

.app-landing .container {
  max-width: 550px;
}

.user-card img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 70px;
}

.comment-input {
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
}

.comment-feed {
  height: 70vh;
  overflow-y: scroll;
}

.time-left {
  padding-right: 0.65rem;
  padding-left: 0.65rem;
  margin-bottom: 0.5rem;
  width: 100%;
  height: 28px;
}

.super-fetch__form {
  height: calc(100% - 147px);
  margin-top: 10px;
}
.super-fetch__generate-container {
  height: 90px;
  position: absolute;
  width: 100%;
  margin: auto;
  right: 0;
  left: 0;
  bottom: 1.5rem;
}

#scroll-text {
  /* animation properties */
  transform: translateX(100%);
  animation: text-scroll 15s linear infinite;
}

.container.checkout {
  min-height: 82vh;
}

.show {
  display: block !important;
}

.show .dropdown-menu {
  display: block !important;
}

#messages {
  overflow-y: auto;
  overflow-x: hidden;
}

#walkthrough-video {
  position: fixed;
  text-align: right;
  justify-content: end;
  right: 15px;
  top: 15px;
  z-index: 1000;
}

#walkthrough-video iframe {
  width: 30vw;
  height: 300px;
  justify-content: end;
}

#walkthrough #message-input {
  width: calc(100% - 50px);
}

#message-input {
  bottom: 0;
  position: relative;
  width: calc(100%);
  height: -webkit-max-content;
  height: max-content;
}

#avatar {
  overflow: hidden;
}

#avatar-content {
  margin-top: 90px;
}

.checkout-content {
  margin-top: 75px;
}

#checkout {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

#avatar-header {
  top: 12px;
  z-index: 100;
  border-radius: 10px;
  width: calc(100% - 250px);
  position: fixed;
  padding-top: 1rem;
}

.message-card {
  width: 90%;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #666666 #ffffff;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #cecece;
}

*::-webkit-scrollbar-track:active {
  background-color: #cecece;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #666666;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #df2a63;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #df2a63;
}

/* for Firefox */

/* for Chrome */

@keyframes text-scroll {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.conversation__templates-list {
  display: absolute;
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
  .class-type-card {
    height: 300px;
  }
}

@media (max-width: 1000px) {
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
}

@media (min-width: 768px) {
  #outputs,
  #trainings {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 768px) {
  #avatar-content {
    margin-top: 0px;
  }

  #message-input {
    width: calc(100% - 28px);
  }
  #avatar-header {
    width: 92%;
    top: 60px;
  }
  #avatar {
    overflow: hidden;
  }
  .mobile-left {
    text-align: left !important;
  }
  .sidebar {
    display: none !important;
  }
  .content {
    margin-left: 0px;
    margin-top: 64px;
    width: 100%;
  }
  .image-gallery-image {
    height: 300px !important;
  }
  .image-gallery-thumbnail-image {
    height: 50px !important;
  }
  .display-1 {
    font-size: 2.5rem;
    line-height: 2;
  }
  h2.h3 {
    font-size: 20px !important;
  }
  .content {
    max-width: 100vw;
  }
  .bottom-navbar {
    display: block;
  }
  .container-fluid.p-5 {
    padding: 12px !important;
  }
  .app-link-overlay {
    height: 25vh;
  }
  .home-img {
    height: 50%;
  }
  .schedule-mobile-container {
    overflow-x: scroll;
  }
  .schedule-mobile-wrapper {
    width: 350%;
  }
  .show-mobile {
    display: inherit !important;
  }
  .show-mobile.d-inline-block {
    display: inline-block !important;
  }
  .schedule-col {
    width: calc(100% / 7);
    margin-top: 32px;
  }
  .panel-mobile-menu {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-left: 0px;
  }
  .text-funny {
    font-size: 32px;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  .slideshow-logo {
    max-width: 250px;
  }
  .latest-card-content {
    width: 100%;
  }
  .latest-card-overlay {
    width: 100%;
  }
  .mobile-center {
    text-align: center;
  }
  .carousel-caption {
    bottom: 20% !important;
  }
  .carousel-caption .display-2 {
    font-size: 2rem;
  }
}

@media (min-width: 576px) {
  .modal-dialog.modal-lg {
    max-width: 75vw !important;
  }
}


 h1, h2, .h1, .h2 {
    font-weight: 800;
}

h3 {
    font-size: 1.125rem !important;
    font-weight: 600;
}

h4, .h4 {
    font-size: 1.2rem;
    font-weight: 600;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.4em;
}

div, h1, h2, h3, h4, h5, h6, p, s, span, a, li {
    font-family: "Poppins", sans-serif !important;
}

div,
a,
input,
body {
    font-size: 0.85rem;
}

p {
    font-size: 1rem;
}

.display-2, .display-3, .display-4, .display-5 {
    font-weight: 700;
}

.btn-sm {
    font-size: 14px;
}
.input-group {
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.input-group .form-control {
    border: 0px;
}

.input-group-text {
    border: 0px;
    background-color: white;
}

.react-tel-input .form-control {
    width: 100% !important;
    padding: 12px 12px 12px 50px;
}

.form-label {
    margin-bottom: 0px;
}

.form-control {
    border: 0.5px solid rgb(229, 231, 235, 0.5);
    padding-left: 16px !important;
    border-radius: 25px;
}

textarea.form-control {
    border-radius: 10px;
}


.form-control:focus-visible, .form-control:focus-within, .form-control:focus {
    box-shadow: none;
}

#avatar__input-file {
    padding-left: 8px !important;
}

.map-container {
    height: 500px;
    width: 100%;
}

@media(max-width: 767px) {
    .map-container {
        height: 85vw;
    }
}

