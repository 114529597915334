#main {
  overflow: hidden;
  max-height: 100vh;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#main::-webkit-scrollbar {
  display: none;
}

#navbar {
  max-width: 100vw;
  overflow: hidden;
}

.ct.ct--bottom {
  display: none;
}

.react-editor .ce-toolbar__settings-btn,
.react-editor .ce-toolbar__plus {
  position: relative;
}

.react-editor .ce-toolbar__settings-btn:hover::before,
.react-editor .ce-toolbar__plus:hover::before {
  font-size: 12px;
  background-color: #333;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 15px;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 999;
}

.react-editor .ce-toolbar__settings-btn:hover::before {
  content: "Actions";
}

/* Additional styles specific to .ce-toolbar__plus */
.react-editor .ce-toolbar__plus:hover::before {
  content: "Insert Element";
}

.offcanvas {
  max-width: 85%;
  padding-top: 5vh;
}

.progress-dot {
  top: 55%;
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50px;
  background-color: var(--primary);
}

.icon-wrapper {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper-lg {
  width: 125px;
  height: 125px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
}

.due-date-card {
  border-left: 8px solid var(--primary) !important;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
}

.step-card,
.activity-card,
.process-card {
  width: 70vw;
  border: 0px;
  height: 139px;
  position: relative;
  display: inline-block;
}

.step-card .progress-container,
.process-card .progress-container {
  position: absolute;
  bottom: 15px;
}

.step-card .metadata-container,
.process-card .metadata-container {
  position: absolute;
  bottom: 25px;
}

.step-card h3,
.activity-card h3,
.process-card h3 {
  height: 2.8rem;
}

.step-metadata,
.activity-metadata,
.process-metadata {
  font-size: 12px;
}

.step-container,
.activity-container,
.process-container {
  max-height: 170px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.step-wrapper,
.activity-wrapper {
  width: 180%;
}

.vimeo-player {
  height: 65vh;
}

.btn-view {
  border-radius: 0px 10px 10px 0px;
  border-width: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 35px;
}

.output-input {
  width: auto;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 2.5rem;
  min-width: 70vw;
}

.output-input:focus {
  box-shadow: none !important;
}

span.input {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom: 1px solid var(--primary);
}

span.input:focus-visible {
  box-shadow: none;
  border-bottom: 1px solid var(--primary);
}

.prompt-generator * :not(.dropdown-item),
.prompt-generator span {
  font-size: 4.5rem;
}

.prompt-generator .dropdowm-item {
  font-size: 2rem;
}

.purchases-table td.datetime,
.purchases-table th.datetime {
  min-width: 150px;
}

.loading {
  font-size: 30px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@media (max-width: 1400px) {
  .prompt-generator * {
    font-size: 4rem;
  }
}

@media (max-width: 1200px) {
  .prompt-generator * {
    font-size: 3.5rem;
  }
}

@media (max-width: 1000px) {
  .prompt-generator * {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  #walkthrough-video iframe {
    width: 60vw;
    height: 200px;
    justify-content: end;
  }
  .prompt-generator * {
    font-size: 2rem;
  }
}
